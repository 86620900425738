.pressed {
	animation: pressed 50ms linear forwards;
}

.released {
	animation: released 0.2s linear forwards;
}

@keyframes pressed {
	0% {
		transform: perspective(1000px) translateZ(0px);
	}
	100% {
		transform: perspective(1000px) translateZ(-100px);
	}
}

@keyframes released {
	0% {
		transform: perspective(1000px) translateZ(-100px);
	}

	25% {
		transform: perspective(1000px) translateZ(100px);
	}
	
	50% {
		transform: perspective(1000px) translateZ(-50px);
	}
	
	75% {
		transform: perspective(1000px) translateZ(50px);
	}

	100% {
		transform: perspective(1000px) translateZ(0px);
	}
}

.Button {
    width: 130px;
    height: 50px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.Image {
	width: 50px;
	height: auto;
	margin: auto;
}

.Link {
    width: 100px;
    height: 40px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid black;
	border-radius: 10px;
}

.Line {
	width: 100%;
    height: 60%;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 5px;
}

.BigLine {
	width: 100%;
    height: 60%;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 5px;
}

.BigButton {
	width: 100%;
    height: 60%;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 1px solid black;
	border-radius: 10px;
}

.Tab {
	flex: 1;
	height: 60%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	box-shadow: 0px 1px 3px #ccc;
}

.SmallTab {
	flex: 1;
	min-width: 150px;
	height: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	box-shadow: 0px 1px 3px #ccc;
}

.InvalidTab {
	flex: 1;
	min-width: 150px;
	height: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	box-shadow: 0px 1px 3px red;
}

.ValidTab {
	position: relative;
	flex: 1;
	min-width: 150px;
	height: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	box-shadow: 0px 1px 3px green;
}

.SmallLine {
	width: 96%;
	margin: auto;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.Small {
	width: 40px;
	min-width: 40px;
    height: 40px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid black;
	border-radius: 5px;
	margin: auto 2px;
}

.SmallSpace {
	width: 60px;
	min-width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin: 5px 5px;
	border: 1px solid black;
	overflow: auto;
}

.Small .Image {
	width: 32px;
	height: auto;
	margin: auto;
}

.SmallSpace .Image {
	width: 50px;
	height: auto;
	margin: auto;
}

.Icon {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 15px;
	height: 15px;
	border: 1px solid red;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Icon .Image {
	width: 96%;
	height: auto;
}


.Td {
    width: 100%;
    height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Td .Image {
	width: 24px;
	height: auto;
	margin: auto;
}


.ValidImg {
	position: absolute;
	top: 10%;
	right: 5px;
	width: auto;
	height: 80%;
}

.Tab:hover {
	color: red;
	font-size: 24px;
}

.SmallTab:hover {
	color: red;
	font-size: 20px;
}

.Small:hover, .Small.Active, .SmallSpace:hover, .SmallSpace.Active {
	border: 1px solid red;
	font-size: 16px;
	font-weight: bold;
}

.Button:hover, .Button.Active, .Tab:hover, .Tab.Active, .SmallTab:hover, .SmallTab.Active {
	box-shadow: 3px 3px 5px black;
}

.InvalidTab:hover, .InvalidTab.Active {
	font-size: 22px;
	box-shadow: 3px 3px 5px red;
}

.ValidTab:hover, .ValidTab.Active {
	font-size: 22px;
	box-shadow: 3px 3px 5px green;
}

.Link:hover, .Link.Active, .Line:hover, .BigLine:hover, .BigLine.Active, .BigButton:hover, .Line.Active {
	box-shadow: 1px 1px 3px black;
}

@media (max-width: 700px) {
	.Button.Active {
		color: red;
	}
	.Line {
		width: 80%;
		font-size: 12px;
	}
	.SmallLine {
		height: 50px;
	}
	.Small {
		width: 40px;
		height: 40px;
		font-size: 12px;
	}
	.Small img {
		width: 16px;
		height: 16px;
	}
	.Small:hover, .Small .Active {
		font-size: 16px;
	}
}