.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;
}

header {
  position: relative;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Logo {
  height: 100%;
  width: auto;
}

.Menu {
  flex: 1;
  height: 100%;
}

.Page {
  position: relative;
  flex: 1;
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.Spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
}

.fullscreen {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.line {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.halfline {
  width: 50%;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixedline {
  width: 100%;
  max-width: 1200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lineStart {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lineSpace {
  width: 30%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.bigText {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.mediumText {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.fond {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 700px) {
  header {
    height: 100px;
  }

  .line {
    height: 50px;
  }

  .bigText {
    font-size: 24px;
  }

  .mediumText {
    font-size: 20px;
  }
}