.Menu {
    margin: auto 30px;
	padding: 0;
	list-style: none;
	display: flex;
    flex-flow: row;
    flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
}

.mobile {
    display: none;
}

@media(max-width: 700px) {
    .MenuItem {
        width: 100px;
        font-size: 16px;
    }

    .Menu {
        position: absolute;
        right: 0px;
        margin: 0;
        flex-flow: column;
        transform: translateX(100vw);
        transition: transform 0.2s ease-in-out;
        background: #ccc;
        z-index: 10;
    }

    .Show {
        transform: translateX(0vw);
    }

    .mobile {
        display: block;
        width: 75px;
        height: auto;
    }
}